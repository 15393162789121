import React, { useState, useEffect, useMemo } from "react";
import Image from "next/image";
import Link from "next/link";
import { Text, Button, Badge } from "rizzui";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  PiMapPin,
  PiCurrencyCircleDollar,
  PiStar,
  PiStarFill,
} from "react-icons/pi";
import hotelPlaceHolder from "@public/hotel.svg";
import { Hotel } from "@/types/hotel";
import { Organization } from "@/types/organization";
import { SearchFilters } from "@/types/search-filters";
import { routes } from "@/config/routes";
import { useCurrentUser } from "@/hooks/useCurrentUser";
import {
  calculatePricingSummaries,
  formatCurrency,
  getPriceRange,
  formatDate,
} from "@/utils/hotelUtils";
import PriceSummary from "./price-summary";

// Custom Image component
const CustomImage = ({ src, alt }: { src: string; alt: string }) => {
  return (
    <div className="relative w-full h-full">
      <Image
        src={src}
        alt={alt}
        layout="fill"
        objectFit="cover"
        className="rounded-lg"
      />
    </div>
  );
};

interface HotelCardProps {
  hotel: Hotel;
  searchFilters: SearchFilters | null;
  organizations: Organization[] | { [id: string]: string } | string;
}

const StarRating: React.FC<{ rating: number | null }> = ({ rating }) => {
  if (rating === 0) {
    return (
      <Badge className="bg-green-100 text-green-800 px-2 py-1 rounded text-sm">
        Economic
      </Badge>
    );
  }

  const stars = [];
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <span key={i}>
        {i <= (rating || 0) ? (
          <PiStarFill className="text-yellow-400 inline" />
        ) : (
          <PiStar className="text-gray-300 inline" />
        )}
      </span>
    );
  }

  return <div className="flex items-center">{stars}</div>;
};

const HotelCard: React.FC<HotelCardProps> = ({
  hotel,
  searchFilters,
  organizations,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [imagesLoaded, setImagesLoaded] = useState(false);
  const { isSuperAdmin } = useCurrentUser();

  useEffect(() => {
    console.log("Hotel photos:", hotel.photos);
    setImagesLoaded(true);
  }, [hotel.photos]);

  const getTranslation = (field: "name" | "description") => {
    const defaultLanguage = "en";
    const translation = hotel.translations.find(
      (t) => t.language === defaultLanguage
    );
    return translation ? translation[field] : "";
  };

  interface OrganizationMap {
    [key: string]: string | Organization;
  }

  const getOrganizationInfo = (
    id: string | number
  ): { name: string; nickname: string } => {
    const stringId = id.toString();

    if (typeof organizations === "string") {
      return { name: organizations, nickname: organizations };
    }

    if (Array.isArray(organizations)) {
      const org = organizations.find((org) => org.id.toString() === stringId);
      return {
        name: org?.name || "Unknown",
        nickname: org?.nickname || "Unknown",
      };
    }

    if (typeof organizations === "object" && organizations !== null) {
      const orgMap = organizations as OrganizationMap;
      const org = orgMap[stringId];
      if (typeof org === "string") {
        return { name: org, nickname: org };
      } else if (
        org &&
        typeof org === "object" &&
        "name" in org &&
        "nickname" in org
      ) {
        return {
          name: org.name || "Unknown",
          nickname: org.nickname || "Unknown",
        };
      }
    }

    return { name: "Unknown", nickname: "Unknown" };
  };

  const priceRange = useMemo(() => getPriceRange(hotel), [hotel]);
  const pricingSummaries = useMemo(
    () => calculatePricingSummaries(hotel, searchFilters),
    [hotel, searchFilters]
  );

  const hasValidPrices = (prices: any) => {
    return Object.values(prices).some((paxPrices: any) => 
      paxPrices.weekday > 0 || paxPrices.weekend > 0
    );
  };

  const hasValidPricingPeriods = (room: any) => {
    return room.pricingPeriods && room.pricingPeriods.some((period: any) => {
      return Object.values(period.prices || {}).some((paxPrices: any) => hasValidPrices(paxPrices));
    });
  };

  return (
    <div className="border-t pt-8 w-full">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="md:w-1/3">
          <div className="w-full h-64 md:h-80">
            <Swiper
              modules={[Navigation, Pagination]}
              spaceBetween={0}
              slidesPerView={1}
              navigation
              pagination={{ clickable: true }}
              className="w-full h-full"
            >
              {hotel.photos && hotel.photos.length > 0 ? (
                hotel.photos.map((photo, index) => (
                  <SwiperSlide key={index} className="w-full h-full">
                    <CustomImage
                      src={photo.url}
                      alt={`${getTranslation("name")} - Photo ${index + 1}`}
                    />
                  </SwiperSlide>
                ))
              ) : (
                <SwiperSlide className="w-full h-full">
                  <CustomImage src={hotelPlaceHolder} alt="Hotel placeholder" />
                </SwiperSlide>
              )}
            </Swiper>
          </div>
        </div>
        <div className="md:w-2/3">
          <div className="flex justify-between items-start">
            <div className="flex flex-col">
              <div className="flex items-center gap-2">
                <Link href={routes.hotels.details(hotel.id.toString())}>
                  <Text className="text-xl font-semibold cursor-pointer hover:underline">
                    {getTranslation("name")}
                  </Text>
                </Link>
                {hotel.area && (
                  <Badge className="bg-blue-100 text-blue-800 px-2 py-1 rounded text-sm">
                    {hotel.area}
                  </Badge>
                )}
                {hotel.isPopular && (
                  <Badge className="bg-gradient-to-r from-yellow-300 to-yellow-400 text-yellow-900 px-3 py-1 rounded text-sm font-semibold shadow-md hover:shadow-lg transition-all duration-300 ease-in-out transform hover:-translate-y-1">
                    <span className="mr-1">⭐</span>
                    Popular
                  </Badge>
                )}
              </div>
              <div className="flex items-center mt-1">
                <PiMapPin className="h-4 w-4 text-gray-500 mr-1" />
                <Text className="text-sm text-gray-500">
                  {hotel.location || "Location not available"}
                </Text>
              </div>
            </div>
            <div className="flex flex-col items-end">
              {priceRange && priceRange.min > 0 && (
                <div className="flex flex-col items-end mb-2">
                  <Text className="text-sm text-gray-500">Starting from</Text>
                  <div className="flex items-center bg-green-100 text-green-800 text-2xl font-bold px-4 py-2 rounded-lg shadow-md transition-all duration-300 ease-in-out hover:bg-green-200 hover:shadow-lg">
                    <PiCurrencyCircleDollar className="h-6 w-6 mr-2" />
                    <span>{formatCurrency(priceRange.min)}</span>
                  </div>
                </div>
              )}
              <StarRating rating={hotel.starRating} />
            </div>
          </div>

          <div className="mt-4 mr-4">
            <Text className="text-sm text-gray-600 leading-relaxed line-clamp-3">
              {getTranslation("description")}
            </Text>
          </div>
          <div className="mt-4">
            <Text className="text-sm text-gray-500">
              (Prices include VAT and Municipality Tax)
            </Text>
            <Text className="text-sm text-gray-500">
              {hotel.rooms.length} room type(s) available
            </Text>
          </div>
          <div className="mt-2">
            {hotel.amenities.slice(0, 6).map((amenity, index) => (
              <Badge
                key={index}
                className="mr-2 mb-2 bg-gray-100 text-gray-800 px-2 py-1 rounded"
              >
                {amenity.value}
              </Badge>
            ))}
          </div>
          <PriceSummary
            pricingSummaries={pricingSummaries}
            getOrganizationInfo={getOrganizationInfo}
            formatCurrency={formatCurrency}
            isSuperAdmin={isSuperAdmin}
          />
          <div className="flex items-center justify-between mt-4">
            <Link href={routes.hotels.details(hotel.id.toString())}>
              <Button variant="outline">See Availability</Button>
            </Link>
            {isSuperAdmin && (
              <Button
                variant="outline"
                onClick={() => setIsExpanded(!isExpanded)}
              >
                {isExpanded ? "Hide Details" : "View Details"}
              </Button>
            )}
          </div>
          {isExpanded && isSuperAdmin && (
            <div className="mt-4">
              {hotel.rooms
                .filter(hasValidPricingPeriods)
                .map((room, index) => (
                  <div key={index} className="mb-4 bg-gray-50 p-4 rounded-lg">
                    <Text className="font-semibold text-lg mb-2">
                      {room.type} (Quantity: {room.quantity})
                    </Text>
                    {room.pricingPeriods &&
                      room.pricingPeriods.map((period, periodIndex) => {
                        const validPrices = Object.entries(period.prices || {}).filter(
                          ([_, paxPrices]) => hasValidPrices(paxPrices)
                        );

                        if (validPrices.length === 0) return null;

                        return (
                          <div key={periodIndex} className="ml-4 mb-3 p-3 bg-white rounded shadow-sm">
                            <Text className="font-medium mb-2">
                              Period: <span className="text-blue-600">{formatDate(period.fromDate)}</span> to{' '}
                              <span className="text-blue-600">{formatDate(period.toDate)}</span>
                            </Text>
                            {validPrices.map(([mealPlan, paxPrices]) => (
                              <div key={mealPlan} className="ml-4 mb-2">
                                <Text className="font-medium text-green-700">{mealPlan}:</Text>
                                {Object.entries(paxPrices).map(([pax, prices]) => {
                                  if (prices.weekday > 0 || prices.weekend > 0) {
                                    return (
                                      <Text key={pax} className="ml-4 text-sm">
                                        {pax} pax:
                                        {prices.weekday > 0 && (
                                          <span className="ml-2 text-blue-700">
                                            Weekday {formatCurrency(prices.weekday)}
                                          </span>
                                        )}
                                        {prices.weekend > 0 && (
                                          <span className="ml-2 text-purple-700">
                                            Weekend {formatCurrency(prices.weekend)}
                                          </span>
                                        )}
                                      </Text>
                                    );
                                  }
                                  return null;
                                })}
                              </div>
                            ))}
                          </div>
                        );
                      })}
                  </div>
                ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HotelCard;
