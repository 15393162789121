import React from 'react';
import { Text } from 'rizzui';
import { PiCurrencyCircleDollar, PiTag } from 'react-icons/pi';

interface PriceSummaryProps {
  pricingSummaries: {
    [organizationId: string]: {
      minPrice: number;
      maxPrice: number;
    };
  } | null;
  getOrganizationInfo: (id: string | number) => { name: string; nickname: string };
  formatCurrency: (amount: number) => string;
  isSuperAdmin: boolean;
}

const PriceSummary: React.FC<PriceSummaryProps> = ({ 
  pricingSummaries, 
  getOrganizationInfo, 
  formatCurrency,
  isSuperAdmin
}) => {
  if (!pricingSummaries || Object.keys(pricingSummaries).length === 0) {
    return null;
  }

  // Get sorted organizations for superadmin view
  const sortedOrgs = isSuperAdmin 
    ? Object.entries(pricingSummaries)
        .map(([orgId, data]) => ({
          id: orgId,
          ...data,
          orgInfo: getOrganizationInfo(orgId)
        }))
        .filter(org => org.minPrice > 0)
        .sort((a, b) => a.minPrice - b.minPrice)
    : [];

  // Keep existing lowest price logic for non-superadmin
  const lowestPriceOrg = Object.entries(pricingSummaries).reduce((lowest, [orgId, data]) => {
    if (data.minPrice > 0 && (!lowest || data.minPrice < lowest.price)) {
      return { id: orgId, price: data.minPrice };
    }
    return lowest;
  }, null as { id: string; price: number } | null);

  if (!isSuperAdmin) {
    if (!lowestPriceOrg) return null;

    const orgInfo = getOrganizationInfo(lowestPriceOrg.id);
    const priceData = pricingSummaries[lowestPriceOrg.id];
    const hasValidPrice = priceData.minPrice > 0 || priceData.maxPrice > 0;

    return (
      <div className="mt-4 bg-gradient-to-r from-blue-50 to-green-50 rounded-lg shadow-md overflow-hidden">
        <div className="px-4 py-3 bg-blue-600 text-white flex items-center">
          <PiTag className="w-5 h-5 mr-2" />
          <Text className="font-semibold text-lg">Best Offer</Text>
        </div>
        <div className="p-4">
          <div className="flex items-center">
            <PiCurrencyCircleDollar className="w-6 h-6 text-green-600 mr-2" />
            {hasValidPrice ? (
              <Text className="text-2xl font-bold text-green-600">
                {priceData.minPrice === priceData.maxPrice 
                  ? formatCurrency(priceData.minPrice)
                  : `${formatCurrency(priceData.minPrice)} - ${formatCurrency(priceData.maxPrice)}`
                }
              </Text>
            ) : (
              <Text className="text-2xl font-bold text-green-600">N/A</Text>
            )}
          </div>
          {!hasValidPrice && (
            <Text className="text-sm text-gray-500 mt-2">
              No prices available for selected criteria
            </Text>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className="mt-4 space-y-4">
      {sortedOrgs.map((org, index) => (
        <div 
          key={org.id}
          className="bg-gradient-to-r from-blue-50 to-green-50 rounded-lg shadow-md overflow-hidden"
        >
          <div className="px-4 py-3 bg-blue-600 text-white flex items-center">
            <PiTag className="w-5 h-5 mr-2" />
            <Text className="font-semibold text-lg">
              {index === 0 ? "Best Offer" : `Offer #${index + 1}`}
            </Text>
          </div>
          <div className="p-4">
            <Text className="font-medium text-gray-800 mb-2 flex items-center">
              <span className="bg-blue-100 text-blue-800 px-2 py-1 rounded-full text-xs mr-2">
                {org.orgInfo.name}
              </span>
              <span className="text-gray-500 text-sm">({org.orgInfo.nickname})</span>
            </Text>
            <div className="flex items-center">
              <PiCurrencyCircleDollar className="w-6 h-6 text-green-600 mr-2" />
              <Text className="text-2xl font-bold text-green-600">
                {org.minPrice === org.maxPrice 
                  ? formatCurrency(org.minPrice)
                  : `${formatCurrency(org.minPrice)} - ${formatCurrency(org.maxPrice)}`
                }
              </Text>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PriceSummary;
