import React, { useState } from 'react';
import { ActionIcon, Modal, Button } from 'rizzui';
import { PiTrashBold } from 'react-icons/pi';

interface DeletePopoverProps {
  title: string;
  description: string;
  onDelete: () => Promise<void>;
}

export default function DeletePopover({ title, description, onDelete }: DeletePopoverProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleDelete = async () => {
    try {
      await onDelete();
      setIsModalOpen(false);
    } catch (error) {
      console.error('Error deleting item:', error);
      // Optionally, you can show an error message to the user here
    }
  };

  return (
    <>
      <div className="relative group">
        <ActionIcon
          size="sm"
          variant="outline"
          className="hover:!border-gray-900 hover:text-gray-700"
          onClick={() => setIsModalOpen(true)}
        >
          <PiTrashBold className="h-4 w-4" />
        </ActionIcon>
        <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-2 py-1 bg-gray-800 text-white text-xs rounded opacity-0 group-hover:opacity-100 transition-opacity duration-200 pointer-events-none">
          Delete
        </div>
      </div>

      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="p-5">
          <h3 className="mb-2 text-lg font-medium text-gray-900">{title}</h3>
          <p className="mb-4 text-sm text-gray-500">
            {description}
          </p>
          <div className="flex justify-end gap-3">
            <Button
              size="sm"
              variant="outline"
              onClick={() => setIsModalOpen(false)}
            >
              Cancel
            </Button>
            <Button
              size="sm"
              variant="solid"
              color="danger"
              onClick={handleDelete}
            >
              Delete
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
}