import { Hotel, Room, PricingPeriod } from "@/types/hotel";
import { SearchFilters } from "@/types/search-filters";
import { differenceInDays, getDay } from "date-fns";
import { MealPlan } from "@/types/hotel";

export const hasValidPricing = (hotel: Hotel): boolean => {
  return hotel.rooms.some((room) =>
    room.pricingPeriods.some((period) =>
      Object.values(period.prices).some((mealPlanPrices) =>
        Object.values(mealPlanPrices).some(
          (priceObj) => priceObj.weekday > 0 || priceObj.weekend > 0
        )
      )
    )
  );
};

export function hasValidPricingFromToday(hotel: Hotel, today: Date): boolean {
  if (!hotel.rooms || hotel.rooms.length === 0) return false;

  return hotel.rooms.some((room) => {
    if (!room.pricingPeriods || room.pricingPeriods.length === 0) return false;
    return room.pricingPeriods.some((period) => {
      const startDate = new Date(period.fromDate);
      return (
        startDate >= today &&
        Object.values(period.prices).some((mealPlanPrices) =>
          Object.values(mealPlanPrices).some(
            (priceObj) => priceObj.weekday > 0 || priceObj.weekend > 0
          )
        )
      );
    });
  });
}

export const calculateFinalPrice = (
  basePrice: number,
  hotel: Hotel
): number => {
  if (basePrice <= 0) return 0;

  const vatRate = hotel.vat || 0;
  const municipalityRate = hotel.municipalityTax || 0;
  const allotmentCommissionRate = hotel.allotmentCommission || 0;

  const vat = basePrice * (vatRate / 100);
  const municipalityTax = basePrice * (municipalityRate / 100);
  const priceWithTaxes = basePrice + vat + municipalityTax;
  const allotmentCommission = priceWithTaxes * (allotmentCommissionRate / 100);

  return priceWithTaxes + allotmentCommission;
};

export const formatCurrency = (amount: number | null) => {
  if (amount === null) return "N/A";
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "SAR",
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(amount);
};

export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

interface PricingSummary {
  [organizationId: string]: {
    minPrice: number;
    maxPrice: number;
  };
}

export const calculatePricingSummaries = (
  hotel: Hotel,
  searchFilters: SearchFilters | null
): PricingSummary | null => {
  console.log("Calculating pricing summaries for hotel:", hotel.id);
  console.log("Search filters:", searchFilters);

  if (!searchFilters || !searchFilters.dateRange) {
    console.log("No search filters or date range provided");
    return null;
  }

  const [fromDate, toDate] = searchFilters.dateRange.map(
    (date) => new Date(date)
  );
  console.log("Date range:", fromDate, "to", toDate);

  const summary: PricingSummary = {};

  hotel.rooms.forEach((room: Room) => {
    console.log("Processing room:", room.type);
    room.pricingPeriods.forEach((period: PricingPeriod) => {
      const periodFromDate = new Date(period.fromDate);
      const periodToDate = new Date(period.toDate);
      console.log("Pricing period:", periodFromDate, "to", periodToDate);

      if (periodFromDate <= toDate && periodToDate >= fromDate) {
        console.log("Period is within search date range");
        const organizationId = period.organizationId?.toString() || "default";
        console.log("Organization ID:", organizationId);

        if (!summary[organizationId]) {
          summary[organizationId] = {
            minPrice: Infinity,
            maxPrice: -Infinity,
          };
        }

        Object.entries(period.prices).forEach(([mealPlan, paxPrices]) => {
          console.log("Processing meal plan:", mealPlan);
          if (
            !searchFilters.mealPlans ||
            searchFilters.mealPlans.includes(mealPlan)
          ) {
            console.log("Meal plan matches search criteria");
            Object.entries(paxPrices).forEach(([pax, prices]) => {
              const occupancy = parseInt(pax);
              console.log("Processing occupancy:", occupancy);
              if (!searchFilters.pax || searchFilters.pax.includes(occupancy)) {
                console.log("Occupancy matches search criteria");
                const weekdayPrice = calculateFinalPrice(prices.weekday, hotel);
                const weekendPrice = calculateFinalPrice(prices.weekend, hotel);
                console.log(
                  "Calculated prices:",
                  "Weekday:",
                  weekdayPrice,
                  "Weekend:",
                  weekendPrice
                );

                if (weekdayPrice > 0) {
                  summary[organizationId].minPrice = Math.min(
                    summary[organizationId].minPrice,
                    weekdayPrice
                  );
                  summary[organizationId].maxPrice = Math.max(
                    summary[organizationId].maxPrice,
                    weekdayPrice
                  );
                }
                if (weekendPrice > 0) {
                  summary[organizationId].minPrice = Math.min(
                    summary[organizationId].minPrice,
                    weekendPrice
                  );
                  summary[organizationId].maxPrice = Math.max(
                    summary[organizationId].maxPrice,
                    weekendPrice
                  );
                }
                console.log(
                  "Updated price range for organization:",
                  organizationId,
                  summary[organizationId]
                );
              } else {
                console.log("Occupancy does not match search criteria");
              }
            });
          } else {
            console.log("Meal plan does not match search criteria");
          }
        });
      } else {
        console.log("Period is outside search date range");
      }
    });
  });

  // Handle cases where no prices were found
  Object.keys(summary).forEach((orgId) => {
    if (summary[orgId].minPrice === Infinity) {
      summary[orgId].minPrice = 0;
    }
    if (summary[orgId].maxPrice === -Infinity) {
      summary[orgId].maxPrice = 0;
    }
  });

  console.log("Final pricing summary:", summary);
  return summary;
};

export const getPriceRange = (
  hotel: Hotel
): { min: number; max: number } | null => {
  console.log(`Calculating price range for hotel ${hotel.id}`);
  let min = Infinity;
  let max = -Infinity;
  let pricesFound = false;
  const today = new Date();
  today.setHours(0, 0, 0, 0); // Set to the start of today

  hotel.rooms.forEach((room: Room) => {
    console.log(`Checking room: ${room.type}`);
    room.pricingPeriods.forEach((period: PricingPeriod) => {
      console.log(
        `Checking pricing period: ${period.fromDate} to ${period.toDate}`
      );
      const periodEndDate = new Date(period.toDate);
      if (periodEndDate >= today && period.prices) {
        Object.values(period.prices).forEach((mealPlan: any) => {
          Object.values(mealPlan).forEach((occupancy: any) => {
            const weekdayPrice = occupancy.weekday;
            const weekendPrice = occupancy.weekend;

            if (typeof weekdayPrice === "number" && weekdayPrice > 0) {
              pricesFound = true;
              const finalWeekdayPrice = Math.ceil(
                calculateFinalPrice(weekdayPrice, hotel)
              );
              min = Math.min(min, finalWeekdayPrice);
              max = Math.max(max, finalWeekdayPrice);
            }

            if (typeof weekendPrice === "number" && weekendPrice > 0) {
              pricesFound = true;
              const finalWeekendPrice = Math.ceil(
                calculateFinalPrice(weekendPrice, hotel)
              );
              min = Math.min(min, finalWeekendPrice);
              max = Math.max(max, finalWeekendPrice);
            }
          });
        });
      }
    });
  });

  if (!pricesFound) {
    console.warn("No valid future prices found for hotel:", hotel.id);
    return null;
  }

  // Ensure min and max are not Infinity or -Infinity
  if (min === Infinity || max === -Infinity) {
    console.warn("Price range calculation issue for hotel:", hotel.id);
    return null;
  }

  return { min, max };
};

const isWeekend = (date: Date): boolean => {
  const day = getDay(date);
  return day === 4 || day === 5; // 4 is Thursday, 5 is Friday
};

export const calculateTotalPrice = (
  hotel: Hotel,
  pricingPeriod: PricingPeriod,
  startDate: Date,
  endDate: Date,
  roomPax: number,
  roomMealPlan: MealPlan
): number => {
  console.log('Calculating total price with inputs:', {
    hotelId: hotel.id,
    pricingPeriodId: pricingPeriod.id,
    startDate,
    endDate,
    roomPax,
    roomMealPlan
  });

  console.log('Full pricing period data:', JSON.stringify(pricingPeriod, null, 2));

  const priceForMealPlan = pricingPeriod.prices[roomMealPlan];
  console.log('Price for meal plan:', JSON.stringify(priceForMealPlan, null, 2));
  if (!priceForMealPlan) {
    console.log('No price found for meal plan:', roomMealPlan);
    return 0;
  }

  const priceForPax = priceForMealPlan[roomPax];
  console.log('Price for pax:', JSON.stringify(priceForPax, null, 2));
  if (!priceForPax) {
    console.log('No price found for pax:', roomPax);
    return 0;
  }

  let totalPrice = 0;
  let currentDate = new Date(startDate);
  const lastDate = new Date(endDate);

  while (currentDate < lastDate) {
    const basePrice = isWeekend(currentDate)
      ? priceForPax.weekend || priceForPax.weekday // Use weekday price if weekend price is 0
      : priceForPax.weekday;
    console.log('Base price for', currentDate, ':', basePrice);
    const finalPrice = calculateFinalPrice(basePrice, hotel);
    console.log('Final price after calculations:', finalPrice);
    totalPrice += finalPrice;
    currentDate.setDate(currentDate.getDate() + 1);
  }

  console.log('Total price calculated:', totalPrice);
  return totalPrice;
};
