'use client';

import PageHeader, { PageHeaderTypes } from '@/app/shared/page-header';
import ImportButton from '@/app/shared/import-button';
import ExportButton from '@/app/shared/export-button';

interface TableLayoutProps {
  title: string;
  breadcrumb: Array<{ href?: string; name: string }>;
  children: React.ReactNode;
  data?: unknown[];
  header?: string;
  fileName?: string;
  action?: React.ReactNode;
}

export default function TableLayout({
  title,
  breadcrumb,
  children,
  data,
  header,
  fileName,
  action,
}: TableLayoutProps) {
  return (
    <>
      <PageHeader {...{ title, breadcrumb }}>
        <div className="mt-4 flex items-center gap-3 @lg:mt-0">
          {/* {fileName && data && header && (
            <ExportButton data={data} fileName={fileName} header={header} />
          )}
          <ImportButton title={'Import File'} /> */}
          {action}
        </div>
      </PageHeader>

      {children}
    </>
  );
}