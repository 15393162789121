import React from 'react';

interface GridProps {
  children: React.ReactNode;
  columns?: number;
  gap?: number;
}

const Grid: React.FC<GridProps> = ({ children, columns = 1, gap = 8 }) => {
  return (
    <div
      className={`grid grid-cols-1 md:grid-cols-${columns} gap-${gap}`}
    >
      {children}
    </div>
  );
};

export default Grid;