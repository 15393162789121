import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { getHotels, createHotel, deleteHotel, updateHotel } from '@/app/api/hotels';
import { Hotel, HotelFormData } from '@/types/hotel';

export function useHotels() {
  const queryClient = useQueryClient();

  const hotelsQuery = useQuery<Hotel[], Error>({
    queryKey: ['hotels'],
    queryFn: getHotels,
  });

  const createHotelMutation = useMutation({
    mutationFn: createHotel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['hotels'] });
    },
  });

  const updateHotelMutation = useMutation({
    mutationFn: (params: { id: string; data: HotelFormData }) => updateHotel(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['hotels'] });
    },
  });

  const deleteHotelMutation = useMutation({
    mutationFn: deleteHotel,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['hotels'] });
    },
  });

  return {
    hotels: hotelsQuery.data,
    isLoading: hotelsQuery.isLoading,
    createHotel: createHotelMutation.mutate,
    updateHotel: updateHotelMutation.mutate,
    deleteHotelMutation,
    refetchHotels: hotelsQuery.refetch,
  };
}