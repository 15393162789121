"use client";

import React, { useCallback, useState, useEffect, useMemo } from "react";
import dynamic from "next/dynamic";
import { useTable } from "@hooks/use-table";
import { useColumn } from "@hooks/use-column";
import { Button, Badge, Tooltip, ActionIcon } from "rizzui";
import ControlledTable from "@/app/shared/controlled-table/index";
import { HeaderCell } from "@/app/shared/table";
import { PiPencilSimpleLine, PiEye, PiTrashBold } from "react-icons/pi";
import Link from "next/link";
import DeletePopover from "@/app/shared/delete-popover";
import { routes } from "@/config/routes";
import { useHotels } from "@/hooks/useHotels";
import { ColumnType } from "rc-table";
import { DefaultRecordType } from "rc-table/lib/interface";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Hotel, HotelStatus } from "@/types/hotel";
import { useCurrentUser } from "@/hooks/useCurrentUser";

const FilterElement = dynamic(
  () => import("@/app/shared/hotels/filter-element"),
  { ssr: false }
);
const TableFooter = dynamic(() => import("@/app/shared/table-footer"), {
  ssr: false,
});

const filterState = {
  priceRange: ["", ""],
  createdAt: [null, null],
  status: "",
};

interface HotelTableProps {
  data: Hotel[];
}

const HotelTable: React.FC<HotelTableProps> = ({ data }) => {
  const [pageSize, setPageSize] = useState(10);
  const queryClient = useQueryClient();
  const { deleteHotelMutation, refetchHotels } = useHotels();
  const { isSuperAdmin } = useCurrentUser();

  const onDeleteItem = useCallback(
    async (id: number) => {
      console.log("Deleting hotel with id:", id);
      try {
        await deleteHotelMutation.mutateAsync(id);
        console.log("Hotel deleted successfully");
        await queryClient.invalidateQueries({ queryKey: ["hotels"] });
        console.log("Queries invalidated");
        await refetchHotels();
        console.log("Hotels refetched");
        // Force a re-render of the component
        setPageSize((prevSize) => prevSize);
      } catch (error) {
        console.error("Error deleting hotel:", error);
      }
    },
    [deleteHotelMutation, queryClient, refetchHotels]
  );

  useEffect(() => {
    console.log("Refetching hotels on component mount");
    refetchHotels();
  }, [refetchHotels]);

  const getTranslation = (
    hotel: Hotel,
    field: "name" | "description"
  ): string => {
    const englishTranslation = hotel.translations.find(
      (t) => t.language === "en"
    );
    if (englishTranslation && englishTranslation[field]) {
      return englishTranslation[field];
    }

    const nonEmptyTranslation = hotel.translations.find((t) => t[field]);
    if (nonEmptyTranslation) {
      return nonEmptyTranslation[field];
    }

    return field === "name" ? "No name provided" : "No description available";
  };

  const getStatusColor = (status: HotelStatus) => {
    switch (status.toLowerCase()) {
      case "active":
        return "bg-green-100 text-green-800";
      case "inactive":
        return "bg-red-100 text-red-800";
      case "pending":
        return "bg-yellow-100 text-yellow-800";
      default:
        return "bg-gray-100 text-gray-800";
    }
  };

  const {
    isLoading: isLoadingTable,
    isFiltered,
    tableData,
    currentPage,
    totalItems,
    handlePaginate,
    filters,
    updateFilter,
    searchTerm,
    handleSearch,
    sortConfig,
    handleSort,
    selectedRowKeys,
    setSelectedRowKeys,
    handleRowSelect,
    handleSelectAll,
    handleDelete,
    handleReset,
  } = useTable(data, pageSize, filterState);

  const columns: ColumnType<Hotel>[] = [
    {
      title: <HeaderCell title="ID" />,
      dataIndex: "id",
      key: "id",
      width: 50,
    },
    {
      title: <HeaderCell title="Name" />,
      dataIndex: "name",
      key: "name",
      width: 200,
      render: (_, hotel: Hotel) => getTranslation(hotel, "name"),
    },
    {
      title: <HeaderCell title="Location" />,
      dataIndex: "location",
      key: "location",
      width: 200,
    },
    {
      title: <HeaderCell title="Star Rating" />,
      dataIndex: "starRating",
      key: "starRating",
      width: 100,
      render: (starRating: number | null) => starRating || "N/A",
    },
    {
      title: <HeaderCell title="Amenities" />,
      dataIndex: "amenities",
      key: "amenities",
      width: 300,
      render: (amenities: { value: string }[]) => (
        <div className="flex flex-wrap gap-2">
          {amenities.slice(0, 5).map((amenity, index) => (
            <span
              key={index}
              className="px-2 py-1 bg-gray-200 text-gray-700 rounded-md text-xs"
            >
              {amenity.value}
            </span>
          ))}
          {amenities.length > 5 && (
            <span className="px-2 py-1 bg-gray-200 text-gray-700 rounded-md text-xs">
              +{amenities.length - 5} more
            </span>
          )}
        </div>
      ),
    },
    {
      title: <HeaderCell title="Status" />,
      dataIndex: "status",
      key: "status",
      width: 120,
      render: (status: HotelStatus) => (
        <span
          className={`px-2 py-1 rounded-md text-xs font-medium ${getStatusColor(status)}`}
        >
          {status}
        </span>
      ),
    },
    {
      title: <HeaderCell title="Popular" />,
      dataIndex: "isPopular",
      key: "isPopular",
      width: 100,
      render: (isPopular: boolean) => (
        <span
          className={`px-2 py-1 rounded-md text-xs font-medium ${isPopular ? "bg-yellow-100 text-yellow-800" : "bg-gray-100 text-gray-800"}`}
        >
          {isPopular ? "Popular" : "Regular"}
        </span>
      ),
    },
    {
      title: <HeaderCell title="Actions" />,
      dataIndex: "action",
      key: "action",
      width: 120,
      render: (_, hotel: Hotel) => (
        <div className="flex items-center gap-3">
          {isSuperAdmin && (
            <Tooltip content="Edit" placement="top">
              <Link href={routes.hotels.edit(hotel.id.toString())}>
                <ActionIcon
                  as="span"
                  size="sm"
                  variant="outline"
                  className="hover:!border-gray-900 hover:text-gray-700"
                >
                  <PiPencilSimpleLine className="h-4 w-4" />
                </ActionIcon>
              </Link>
            </Tooltip>
          )}
          <Tooltip content="View" placement="top">
            <Link href={routes.hotels.details(hotel.id.toString())}>
              <ActionIcon
                as="span"
                size="sm"
                variant="outline"
                className="hover:!border-gray-900 hover:text-gray-700"
              >
                <PiEye className="h-4 w-4" />
              </ActionIcon>
            </Link>
          </Tooltip>
          {isSuperAdmin && (
            <DeletePopover
              title="Delete Hotel"
              description="Are you sure you want to delete this hotel?"
              onDelete={async () => {
                await onDeleteItem(hotel.id);
                console.log("Delete operation completed");
              }}
            />
          )}
        </div>
      ),
    },
  ];

  const { visibleColumns, checkedColumns, setCheckedColumns } =
    useColumn(columns);

  // Cast visibleColumns to the expected type
  const typedVisibleColumns = visibleColumns as unknown as ColumnType<Hotel>[];

  return (
    <>
      <ControlledTable
        variant="modern"
        data={tableData}
        isLoading={isLoadingTable}
        showLoadingText={true}
        columns={
          typedVisibleColumns as unknown as ColumnType<DefaultRecordType>[]
        }
        paginatorOptions={{
          pageSize,
          setPageSize,
          total: totalItems,
          current: currentPage,
          onChange: (page: number) => handlePaginate(page),
        }}
        filterOptions={{
          searchTerm,
          onSearchClear: () => {
            handleSearch("");
          },
          onSearchChange: (event) => {
            handleSearch(event.target.value);
          },
          hasSearched: isFiltered,
          columns,
          checkedColumns,
          setCheckedColumns,
        }}
        // filterElement={
        //   <FilterElement
        //     isFiltered={isFiltered}
        //     filters={filters}
        //     updateFilter={updateFilter}
        //     handleReset={handleReset}
        //   />
        // }
        tableFooter={
          <TableFooter
            checkedItems={selectedRowKeys}
            handleDelete={(ids: string[]) => {
              setSelectedRowKeys([]);
              handleDelete(ids);
            }}
          >
            <Button size="sm" className="dark:bg-gray-300 dark:text-gray-800">
              Update {selectedRowKeys.length}{" "}
              {selectedRowKeys.length > 1 ? "Hotels" : "Hotel"}
            </Button>
          </TableFooter>
        }
        className="rounded-md border border-muted text-sm shadow-sm [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:h-60 [&_.rc-table-placeholder_.rc-table-expanded-row-fixed>div]:justify-center [&_thead.rc-table-thead]:border-t-0"
      />
    </>
  );
};

export default HotelTable;
