import React, { useState, useRef, useEffect } from "react";
import { Button, Select, Input } from "rizzui";
import {
  PiUsers,
  PiMapPin,
  PiCalendar,
  PiCoffee,
  PiMinus,
  PiPlus,
  PiMagnifyingGlass,
} from "react-icons/pi";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useHotelSearch } from "@/hooks/useHotelSearch";
import { addDays } from 'date-fns';

const HotelSearchBar: React.FC<{ onSearch: (filters: any) => void }> = ({
  onSearch,
}) => {
  const {
    location,
    dateRange,
    rooms,
    pax,
    mealPlans,
    searchQuery,
    updateSearch,
  } = useHotelSearch();

  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [roomsInput, setRoomsInput] = useState(rooms.toString());
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [isMealPlanDropdownOpen, setIsMealPlanDropdownOpen] = useState(false);
  const datePickerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const mealPlanDropdownRef = useRef<HTMLDivElement>(null);

  const cityOptions = [
    { value: "Al Madinah", label: "Al Madinah" },
    { value: "Makkah", label: "Makkah" },
    { value: "Makkah & Al Madinah", label: "Makkah & Al Madinah" },
  ];

  const mealPlanOptions = [
    { value: "RO", label: "Room Only" },
    { value: "BB", label: "Bed & Breakfast" },
    { value: "HB", label: "Half Board" },
    { value: "FB", label: "Full Board" },
  ];

  const formatDateRange = (range: [Date | null, Date | null]) => {
    if (range[0] && range[1]) {
      const options: Intl.DateTimeFormatOptions = {
        month: "short",
        day: "numeric",
      };
      return `${range[0].toLocaleDateString("en-US", options)} - ${range[1].toLocaleDateString("en-US", options)}`;
    }
    return "Select dates";
  };

  const incrementRooms = () => {
    const newValue = Math.min(rooms + 1, 100);
    updateSearch({ rooms: newValue });
    setRoomsInput(newValue.toString());
  };

  const decrementRooms = () => {
    const newValue = Math.max(rooms - 1, 1);
    updateSearch({ rooms: newValue });
    setRoomsInput(newValue.toString());
  };

  const handleRoomChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setRoomsInput(value);

    if (value === "") {
      updateSearch({ rooms: 1 });
    } else {
      const numValue = parseInt(value, 10);
      if (!isNaN(numValue)) {
        updateSearch({ rooms: Math.max(1, Math.min(numValue, 100)) });
      }
    }
  };

  const handleRoomInputBlur = () => {
    if (roomsInput === "" || parseInt(roomsInput, 10) < 1) {
      updateSearch({ rooms: 1 });
      setRoomsInput("1");
    } else if (parseInt(roomsInput, 10) > 100) {
      updateSearch({ rooms: 100 });
      setRoomsInput("100");
    } else {
      updateSearch({ rooms: parseInt(roomsInput, 10) });
      setRoomsInput(rooms.toString());
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        datePickerRef.current &&
        !datePickerRef.current.contains(event.target as Node)
      ) {
        setIsDatePickerOpen(false);
      }
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsDropdownOpen(false);
      }
      if (
        mealPlanDropdownRef.current &&
        !mealPlanDropdownRef.current.contains(event.target as Node)
      ) {
        setIsMealPlanDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleSearch = () => {
    const searchFilters = {
      location,
      dateRange,
      rooms,
      pax,
      mealPlans,
      searchQuery,
    };
    console.log("Search filters:", searchFilters);
    onSearch(searchFilters);
  };

  const paxOptions = [
    { value: 2, label: "2 PAX" },
    { value: 3, label: "3 PAX" },
    { value: 4, label: "4 PAX" },
    { value: 5, label: "5 PAX" },
    { value: 6, label: "6 PAX" },
    { value: 7, label: "7 PAX" },
    { value: 8, label: "8 PAX" },
  ];

  const handlePaxChange = (value: number) => {
    updateSearch({
      pax: pax.includes(value)
        ? pax.filter((p) => p !== value)
        : [...pax, value].sort((a, b) => a - b),
    });
  };

  const handleMealPlanChange = (value: string) => {
    updateSearch({
      mealPlans: mealPlans.includes(value)
        ? mealPlans.filter((mp) => mp !== value)
        : [...mealPlans, value],
    });
  };

  const getDisplayValue = () => {
    if (pax.length === 0) return "Select PAX";

    const sortedPax = [...pax].sort((a, b) => a - b);
    let ranges = [];
    let start = sortedPax[0];
    let end = start;

    for (let i = 1; i <= sortedPax.length; i++) {
      if (sortedPax[i] !== end + 1) {
        ranges.push(start === end ? `${start}` : `${start}-${end}`);
        start = sortedPax[i];
      }
      end = sortedPax[i];
    }

    return ranges.join(", ") + " PAX";
  };

  const getMealPlanDisplayValue = () => {
    if (mealPlans.length === 0) return "Select Meal Plan";
    if (mealPlans.length === mealPlanOptions.length) return "All Meal Plans";

    const selectedLabels = mealPlans.map(
      (mp) => mealPlanOptions.find((option) => option.value === mp)?.value
    );

    return selectedLabels.join(", ");
  };

  const handleDropdownClick = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const today = new Date();

  return (
    <div className="flex flex-col md:flex-row items-center gap-2 bg-white pb-10 rounded-lg shadow-sm w-full">
      <div className="w-full md:w-1/6">
        <Select
          options={cityOptions}
          value={location}
          onChange={(value: string) => updateSearch({ location: value })}
          placeholder="Where are you going?"
          prefix={<PiMapPin className="text-gray-500" />}
          className="w-full"
        />
      </div>
      <div className="w-full md:w-1/6">
        <Input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={(e) => updateSearch({ searchQuery: e.target.value })}
          prefix={<PiMagnifyingGlass className="text-gray-500" />}
          className="w-full"
        />
      </div>
      <div className="w-full md:w-2/5 relative">
        <Button
          variant="outline"
          className="w-full justify-between"
          onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
        >
          <span className="flex items-center">
            <PiCalendar className="mr-2 text-gray-500" />
            {formatDateRange(dateRange)}
          </span>
        </Button>
        {isDatePickerOpen && (
          <div
            ref={datePickerRef}
            className="absolute left-1/2 transform -translate-x-1/2 mt-1 z-50 bg-white shadow-lg rounded-lg"
          >
            <style>
              {`
                .react-datepicker {
                  display: flex !important;
                }
                .react-datepicker__month-container {
                  float: none !important;
                }
              `}
            </style>
            <DatePicker
              inline
              selectsRange={true}
              startDate={dateRange[0]}
              endDate={dateRange[1]}
              onChange={(update: [Date | null, Date | null]) => {
                updateSearch({ dateRange: update });
                if (update[0] && update[1]) setIsDatePickerOpen(false);
              }}
              monthsShown={2}
              minDate={today}
              maxDate={addDays(today, 365)} // Optional: set a maximum date (e.g., 1 year from today)
            />
          </div>
        )}
      </div>
      <div className="w-full md:w-1/5">
        <div className="flex items-center justify-between border border-gray-300 rounded-md h-10 overflow-hidden">
          <button
            onClick={decrementRooms}
            className="text-gray-500 hover:text-gray-700 focus:outline-none px-3 h-full border-r border-gray-300"
            disabled={rooms === 1}
          >
            <PiMinus size={20} />
          </button>
          <input
            type="text"
            inputMode="numeric"
            pattern="[0-9]*"
            value={roomsInput}
            onChange={handleRoomChange}
            onBlur={handleRoomInputBlur}
            className="w-20 text-center text-lg font-semibold border-none focus:outline-none"
          />
          <span className="mr-2">{rooms === 1 ? "Room" : "Rooms"}</span>
          <button
            onClick={incrementRooms}
            className="text-gray-500 hover:text-gray-700 focus:outline-none px-3 h-full border-l border-gray-300"
            disabled={rooms === 100}
          >
            <PiPlus size={20} />
          </button>
        </div>
      </div>
      <div className="w-full md:w-1/5 relative" ref={dropdownRef}>
        <Button
          variant="outline"
          className="w-full justify-between"
          onClick={handleDropdownClick}
        >
          <span className="flex items-center">
            <PiUsers className="mr-2 text-gray-500" />
            {getDisplayValue()}
          </span>
        </Button>
        {isDropdownOpen && (
          <div className="absolute top-full left-0 right-0 mt-1 bg-white border border-gray-300 rounded-md p-2 z-10">
            <div className="flex flex-wrap gap-1">
              {paxOptions.map((option) => (
                <Button
                  key={option.value}
                  size="sm"
                  variant={pax.includes(option.value) ? "solid" : "outline"}
                  onClick={() => handlePaxChange(option.value)}
                >
                  {option.label}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="w-full md:w-1/5 relative" ref={mealPlanDropdownRef}>
        <Button
          variant="outline"
          className="w-full justify-between"
          onClick={() => setIsMealPlanDropdownOpen(!isMealPlanDropdownOpen)}
        >
          <span className="flex items-center">
            <PiCoffee className="mr-2 text-gray-500" />
            {getMealPlanDisplayValue()}
          </span>
        </Button>
        {isMealPlanDropdownOpen && (
          <div className="absolute top-full left-0 right-0 mt-1 bg-white border border-gray-300 rounded-md p-2 z-10">
            <div className="flex flex-wrap gap-1">
              {mealPlanOptions.map((option) => (
                <Button
                  key={option.value}
                  size="sm"
                  variant={
                    mealPlans.includes(option.value) ? "solid" : "outline"
                  }
                  onClick={() => handleMealPlanChange(option.value)}
                >
                  {option.label}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="w-full md:w-1/6">
        <Button
          className="w-full bg-blue-600 text-white"
          onClick={handleSearch}
        >
          Search
        </Button>
      </div>
    </div>
  );
};

export default HotelSearchBar;
