import React, { useState, useEffect, useMemo } from "react";
import { Hotel, Room, PricingPeriod, MealPlan } from "@/types/hotel";
import HotelSearchBar from "./components/hotel-searchbar";
import HotelCard from "./components/hotel-card";
import Grid from "./components/grid";
import Pagination from "./components/pagination";
import { fetchOrganizations } from "@/app/api/organizations"; // You'll need to create this API function
import { Organization } from "@/types/organization";

// Helper function to calculate maxOccupancy for a room
const calculateMaxOccupancy = (room: Room): number => {
  let maxOccupancy = 0;
  room.pricingPeriods.forEach((period: PricingPeriod) => {
    Object.values(period.prices).forEach((mealPlanPrices) => {
      const maxPaxForPeriod = Math.max(
        ...Object.keys(mealPlanPrices).map(Number)
      );
      maxOccupancy = Math.max(maxOccupancy, maxPaxForPeriod);
    });
  });
  console.log(`Calculated max occupancy for room: ${maxOccupancy}`);
  return maxOccupancy;
};

interface HotelGridProps {
  data: Hotel[];
}

const HotelGrid: React.FC<HotelGridProps> = ({ data }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<any>(null);
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const itemsPerPage = 9;

  // Fetch organizations
  useEffect(() => {
    const fetchOrganizationData = async () => {
      try {
        const orgData = await fetchOrganizations();
        setOrganizations(orgData);
      } catch (error) {
        console.error("Error fetching organizations:", error);
      }
    };

    fetchOrganizationData();
  }, []);
 
  
  const filteredHotels = useMemo(() => {
    console.log('Filtering hotels with filters:', filters);
    console.log('Total hotels before filtering:', data.length);
    if (!filters) return data;
    
    return data.filter((hotel: Hotel) => {
      console.log(`Checking hotel: ${hotel.id}`);

      // Full-text search filtering
      if (filters.searchQuery) {
        const searchTerm = filters.searchQuery.toLowerCase();
        const searchableText = hotel.translations.map(t => `${t.name} ${t.description}`).join(' ').toLowerCase() + 
                               ` ${hotel.city || ''} ${hotel.country || ''}`.toLowerCase();
        if (!searchableText.includes(searchTerm)) {
          console.log(`Hotel ${hotel.id} filtered out due to search query`);
          return false;
        }
      }

      // City filtering
      if (filters.location && hotel.city) {
        const hotelCity = hotel.city.toLowerCase();
        const filterCity = filters.location.value.toLowerCase();
        console.log("filterCity:", filterCity);
        if (filterCity === "makkah & al madinah") {
          if (hotelCity !== "makkah" && hotelCity !== "al madinah") {
            console.log(`Hotel ${hotel.id} filtered out due to city`);
            return false;
          }
        } else if (filterCity && hotelCity !== filterCity) {
          console.log(`Hotel ${hotel.id} filtered out due to city`);
          return false;
        }
      }

      // Date range filtering
      if (filters.dateRange && filters.dateRange[0] && filters.dateRange[1]) {
        const startDate = new Date(filters.dateRange[0]);
        const endDate = new Date(filters.dateRange[1]);
        const hasAvailability = hotel.rooms.some((room) =>
          room.pricingPeriods.some(
            (period) =>
              new Date(period.fromDate) <= endDate &&
              new Date(period.toDate) >= startDate
          )
        );
        if (!hasAvailability) {
          console.log(`Hotel ${hotel.id} filtered out due to date range`);
          return false;
        }
      }

      // Rooms filtering - check if total quantity of rooms meets or exceeds the requested number
      if (filters.rooms) {
        const totalRooms = hotel.rooms.reduce(
          (sum, room) => sum + room.quantity,
          0
        );
        if (totalRooms < filters.rooms) {
          console.log(
            `Hotel ${hotel.id} filtered out due to insufficient rooms. Total: ${totalRooms}, Requested: ${filters.rooms}`
          );
          return false;
        }
      }

      // PAX filtering
      if (filters.pax && filters.pax.length > 0) {
        const maxRequestedPax = Math.max(...filters.pax);
        const canAccommodate = hotel.rooms.some((room) => {
          const maxOccupancy = calculateMaxOccupancy(room);
          console.log(
            `Hotel ${hotel.id} - Room max occupancy: ${maxOccupancy}, Max requested PAX: ${maxRequestedPax}`
          );
          return maxOccupancy >= maxRequestedPax;
        });
        if (!canAccommodate) {
          console.log(`Hotel ${hotel.id} filtered out due to PAX requirements`);
          return false;
        }
      }

      // Meal Plan filtering
      if (filters.mealPlans && filters.mealPlans.length > 0) {
        const hasRequestedMealPlan = hotel.rooms.some((room) =>
          room.pricingPeriods.some((period) =>
            filters.mealPlans.some((mealPlan: MealPlan) =>
              Object.keys(period.prices).includes(mealPlan)
            )
          )
        );
        if (!hasRequestedMealPlan) {
          console.log(
            `Hotel ${hotel.id} filtered out due to meal plan requirements`
          );
          return false;
        }
      }

      console.log(`Hotel ${hotel.id} passed all filters`);
      return true;
    });
  }, [data, filters]);

  console.log("Filtered hotels:", filteredHotels.length);

  const totalPages = useMemo(
    () => Math.ceil(filteredHotels.length / itemsPerPage),
    [filteredHotels.length, itemsPerPage]
  );

  const currentHotels = useMemo(() => {
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    return filteredHotels.slice(indexOfFirstItem, indexOfLastItem);
  }, [filteredHotels, currentPage, itemsPerPage]);

  const handlePageChange = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const handleSearch = (searchFilters: any) => {
    console.log("Received search filters:", searchFilters);
    setFilters(searchFilters);
    setCurrentPage(1);
  };

  return (
    <div>
      <HotelSearchBar onSearch={handleSearch} />
      <Grid>
        {currentHotels.map((hotel: Hotel) => (
          <HotelCard
            key={hotel.id}
            hotel={hotel}
            searchFilters={filters}
            organizations={organizations}
          />
        ))}
      </Grid>
      <Pagination
        currentPage={currentPage}
        totalPages={totalPages}
        onPageChange={handlePageChange}
      />
    </div>
  );
};

export default HotelGrid;
